import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'
import classNames from 'classnames'
import axios from 'axios'
import detect from 'detect.js'
import useRoomContext from '../../context/roomContext'
import {setIsActiveChatMessenger, setIsVisibleChatMenu} from '../../store/actions/chats'
import {setModal, setModalService} from '../../store/actions/mediaDevice'
import {setClientError} from '../../store/actions/clientError'

import {selectCanvasToolbarState} from '../../store/selectors/video'
import {
    selectChatDuration,
    selectChatId, selectChatStartTime,
    selectIsActiveChatMessanger,
    selectIsVisibleChatMenu,
    selectOrderStartTime
} from '../../store/selectors/chats'
import {selectOrder} from '../../store/selectors/order'
import {selectModal} from '../../store/selectors/mediaDevice'
import {selectClientError} from '../../store/selectors/clientError'

import Messanger from '../../components/messanger'
import Header from '../../components/Header'
import Toolbar from '../../components/Header/Toolbar'
// import ModalArbitration from '../../components/ModalArbitration'
import ModalArbitration from '../../components/ModalArbitration'
import ModalMediaResolution from '../../components/ModalMediaResolution'
import ModalError from '../../components/ModalError'
import ClientNotice from '../../components/ClientNotice'

import downArrow from '../../assets/icons/down-arrow.svg'
import boardFooterBuuton from '../../assets/icons/board-footer-button.svg'
import chatFooterBuuton from '../../assets/icons/chat-footer-button.svg'
import leaveRoomSvg from '../../assets/icons/leave-room.svg'
import arbitrationSvg from '../../assets/icons/arbitration-room.svg'
import loadingIcon from '../../assets/icons/loading.svg'
import avatar from '../../assets/icons/avatar.svg'
import panoramicModeSvg from '../../assets/icons/panoramic-mode.svg'
import deviceSettingsIcon from '../../assets/icons/device-settings.svg'
import Modal from 'react-modal'
import cloudUploadIcon from '../../assets/icons/cloud-upload.svg'
import CloseIcon from '@mui/icons-material/Close'
import Loader from '../../assets/loader.svg'
import './Workspace.scss'
import MessangerService from '../../components/messanger/service'
import Button from '../../components/UI/button'
import {setCanvasToolbarState} from '../../store/actions/video'
import cameraFooterBuuton from '../../assets/icons/camera-footer-button.svg'
import {roomError} from './constants'
import {WorkspaceService} from './service'
import notificationSound from '../../assets/sounds/notification.mp3'
import moment from 'moment'

const TabBarItem = Object.freeze({
    TEACHER: 1,
    BOARD: 2,
    CAMERA: 3,
    CHAT: 4
})

function Workspace({ roomtrain }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { socket } = useRoomContext()
    const { token, orderId } = useParams()
    const intervalRef = useRef()
    const [chatDetails, setChatDetails] = useState(null) // eslint-disable-line
    const [error, setError] = useState('')

    const isActiveChatMessenger = useSelector(selectIsActiveChatMessanger)
    const isVisibleChatMenu = useSelector(selectIsVisibleChatMenu)
    const showCanvasToolbar = useSelector(selectCanvasToolbarState)
    const showModalMediaDevice = useSelector(selectModal)

    const now = new Date().getTime()

    const [infoMessagesInChat, setInfoMessagesInChat] = useState(null)
    const [onStart, setOnStart] = useState(null)
    const orderStart = useSelector(selectOrderStartTime)
    const chatDuration = useSelector(selectChatDuration)
    const chatDurationInMs = chatDuration * 60 * 1000

    const [countDown, setCountDown] = useState(0)

    const order = useSelector(selectOrder)

    const timestampStartLesson = moment(order?.datetime).unix() * 1000;
    const timeUntilStart = timestampStartLesson - now;

    const [bbbUrl, setBbbUrl] = useState('')

    const [unreadMsg, setUnreadMsg] = useState(false)

    /** @type {React.MutableRefObject<HTMLIFrameElement>} */
    const iframeRef = useRef()

    useEffect(() => {
        window.addEventListener('message', (message) => {
            switch (message.data) {
                case 'chat-showed':
                    console.log('root |', message)
                    dispatch(setIsActiveChatMessenger(true))
                    break
                case 'chat-hided':
                    console.log('root |', message)
                    dispatch(setIsActiveChatMessenger(false))
                    break
                case 'request-chat-state':
                    console.log('root |', message)
                    iframeRef.current &&
                        iframeRef.current.contentWindow.postMessage(
                            isActiveChatMessenger ? 'show-chat' : 'hide-chat',
                            '*'
                        )
                    break
                case 'chat-unread-msg':
                    console.log('root |', message)
                    setUnreadMsg(true)
                    break
                case 'chat-unread-msg-viewed':
                    console.log('root |', message)
                    setUnreadMsg(false)
                    break
                case 'chat-add-info-message':
                    console.log('root |', message)
                    break
                case roomError.cookiesPolicy:
                    setError(message.data)
                    break
                case 'request-lesson-start':
                    orderStart !== 0 && setOnStart(true)
                    break
                default:
                    break
            }
        })
    }, [orderStart]) // eslint-disable-line
    const hours =
        countDown > 0 ? Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) : 0
    const minutes = countDown > 0 ? Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60)) : 0
    const seconds = countDown > 0 ? Math.floor((countDown % (1000 * 60)) / 1000) : 0

    const [teacherAvatar, setTeacherAvtar] = useState(null)

    const [activeFooterItem, setActiveFooterItem] = useState(TabBarItem.BOARD)
    const switchTab = (tabId) => {
        setActiveFooterItem(tabId)

        if (tabId === TabBarItem.CHAT) {
            dispatch(setIsActiveChatMessenger(true))
            iframeRef.current && iframeRef.current.contentWindow.postMessage('show-chat', '*')
        } else {
            dispatch(setIsActiveChatMessenger(false))
            iframeRef.current && iframeRef.current.contentWindow.postMessage('hide-chat', '*')
        }

        if (tabId === TabBarItem.CAMERA) {
            iframeRef.current && iframeRef.current.contentWindow.postMessage('show-cameras', '*')
        } else {
            iframeRef.current && iframeRef.current.contentWindow.postMessage('hide-cameras', '*')
        }

        if ([TabBarItem.BOARD, TabBarItem.TEACHER].includes(tabId)) {
            // nothing
        }
    }

    const chatId = useSelector(selectChatId)

    const [isArbitration, setIsArbitration] = useState(false)

    const [loading, setLoading] = useState(false)

    const clientError = useSelector(selectClientError)

    const closeClientErrorModal = () => {
        dispatch(setClientError(null))
    }

    const [clientNotice, setClientNotice] = useState(null)
    const closeClientNotice = () => {
        setClientNotice(null)
    }

    const [fileModalVisible, setFileModalVisible] = useState(false)
    const openFilesModal = () => {
        setFileModalVisible(true)
        dispatch(setCanvasToolbarState(false))
    }

    const checkForAccidentalButtonPresses = () => {
        dispatch(setCanvasToolbarState(false))
        setIsArbitration(true)
    }

    const resetArbitration = () => {
        setIsArbitration(false)
    }

    const onChatLeave = () => {
        dispatch(setCanvasToolbarState(false))
        if (window.confirm('Вы действительно хотите выйти из комнаты?')) {
            socket.emit('public-chats-leave', { chatId, type: 'classroom-workplace' }, (res) => {
                navigate(`/room/${orderId}/${token}/completed`)
            })
        }
    }

    const onExpressChatLeave = async () => {
        socket.emit(
            'public-chats-leave',
            { chatId, type: 'classroom-workplace-express' },
            (res) => {
                console.log(res)
            }
        )

        try {
            setLoading(true)

            const config = {
                method: 'post',
                url: `${process.env.REACT_APP_API_URL}/public/arbitration/${orderId}`,
                data: { isByEmergencyExit: true },
                headers: {
                    'x-access-token': token
                }
            }

            const { data } = await axios(config)

            if (data?.success) {
                setIsArbitration(false)
            }
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    const openModalMediaSettings = () => {
        dispatch(setModalService('Продолжить занятие'))
        dispatch(setModal(true))
        dispatch(setCanvasToolbarState(false))
    }

    const playSound = (sound) => {
        const audio = new Audio(sound)
        audio.play()
    }

    useEffect(() => {
        if (!roomtrain) return

        const initRoomtrain = async () => {
            const res = await axios('/public/orders/train', {
                baseURL: process.env.REACT_APP_API_URL
            })

            setBbbUrl(res.data.result)
        }

        initRoomtrain()
    }, [roomtrain])

    useEffect(() => {

        // обнуление таймера, что бы он не был со знаком "-"
        if (countDown < 0 || orderStart === 0 && timeUntilStart < 0) {
            clearInterval(intervalRef.current)
            return
        }

        // Время до старта занятия (убывает), когда подключено 2 пользователя, но занятие ещё не стартануло
        if (timeUntilStart > 0) {
            intervalRef.current = setInterval(() => {
                setCountDown(timeUntilStart)
            }, 1000)
        }

        // Таймер занятия, если оно стартануло
        if (orderStart > 0 && timeUntilStart < 0) {
            clearInterval(intervalRef.current)
            intervalRef.current = setInterval(() => {
                setCountDown(orderStart + chatDurationInMs - now)
            }, 1000)

            return () => clearInterval(intervalRef.current)
        }

        return () => clearInterval(intervalRef.current)
    }, [now, orderStart, countDown, chatDurationInMs])

    useEffect(() => {
        if (!hours && minutes === 5) {
            playSound(notificationSound)
            setClientNotice({
                title: 'Уведомление',
                text: 'У вас осталось 5 минут, самое время подведести итоги занятия.'
            })
            setTimeout(() => {
                setClientNotice(null)
            }, 10000)
        }
    }, [minutes]) // eslint-disable-line

    useEffect(() => {
        const userAgent = detect.parse(navigator.userAgent)
        const browser = userAgent.browser.family
        console.log('browser: ', browser)
    }, [dispatch])

    useEffect(() => {
        iframeRef.current && iframeRef.current.contentWindow.postMessage('set-info-message', '*')
    }, [infoMessagesInChat])

    useEffect(() => {
        if (orderStart !== 0 && timeUntilStart < 0){
            iframeRef.current && iframeRef.current.contentWindow.postMessage('handle-lesson-start', '*')
        }
     }, [onStart, timeUntilStart])

    return (
        <div className="workspace">
            <Header
                menuButton={
                    <button
                        onClick={() => dispatch(setIsVisibleChatMenu(!isVisibleChatMenu))}
                        className={classNames(
                            'header__profile-arrow',
                            isVisibleChatMenu ? 'active' : ''
                        )}
                    >
                        <img src={downArrow} alt="arrow" />
                    </button>
                }
                className="header"
                toolbar={
                    <Toolbar
                        iframeRef={iframeRef}
                        roomtrain={roomtrain}
                        unreadMsg={unreadMsg}
                        openFilesModal={openFilesModal}
                    />
                }
                timer
                hours={hours}
                minutes={minutes}
                seconds={seconds}
                settings
            />
            <main
                className={classNames(
                    'room',
                    activeFooterItem === TabBarItem.CHAT ? 'room_chat' : ''
                )}
            >
                {!bbbUrl && !error && (
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <img src={Loader} alt="Загрузка..." style={{ width: 75, height: 75 }} />
                    </div>
                )}

                {bbbUrl && !error && (
                    <div className="iframe-container">
                        <iframe
                            ref={iframeRef}
                            src={bbbUrl}
                            // src={bbbUrl.replace('bbb-1.my5plus.online', 'localhost')} // 🚧 must be commented
                            title="description"
                            width="100%"
                            height="100%"
                            // allowusermedia
                            // allowfullscreen
                            allow="microphone *;camera *;display-capture *;fullscreen *"
                        />
                    </div>
                )}

                {[roomError.unknown, roomError.cookiesPolicy].includes(error) && (
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 30
                        }}
                    >
                        <p style={{ textAlign: 'center' }}>
                            {error === roomError.cookiesPolicy && (
                                <span>
                                    Ошибка сторонних файлов cookie.
                                    <br />
                                    Для работы разрешите использование сторонних фалов cookie в
                                    настройках, после чего перезагрузите страницу.
                                    <br />
                                    Так же, если вы открыли страницу в режиме инкогнито, откройте
                                    её в обычном окне.
                                </span>
                            )}
                            {error === roomError.unknown && <span>Неизвестная ошибка</span>}
                        </p>

                        <div style={{ marginTop: 10 }}>
                            <Button type="secondary" onClick={() => window.location.reload()}>
                                Перезагрузить
                            </Button>
                        </div>
                    </div>
                )}

                <div
                    className={classNames(
                        'room__info',
                        [TabBarItem.BOARD, TabBarItem.TEACHER, TabBarItem.CAMERA].includes(
                            activeFooterItem
                        )
                            ? 'active'
                            : 'hidden'
                    )}
                >
                    <h4 className="room__info__title">Информация о занятии</h4>
                    <div className="room__info__content">
                        <div className="room__info__content-left">
                            <span>Направление:</span>
                            <p>{order?.teachingType?.name ?? ''}</p>

                            {order?.subjectId && <span>Предмет:</span>}
                            {order?.subjectId && <p>{order?.subject?.name ?? ''}</p>}
                        </div>
                        <div className="room__info__content-right">
                            <div className="room__info__content-avatar">
                                {teacherAvatar ? (
                                    <img src={teacherAvatar} alt="avatar" />
                                ) : (
                                    <img src={avatar} alt="avatar" />
                                )}
                            </div>
                            <p>
                                {order?.teacher?.attributes?.firstName ?? ''}{' '}
                                {order?.teacher?.attributes?.middleName ?? ''}{' '}
                                {order?.teacher?.attributes?.lastName ?? ''}
                            </p>
                            <span>педагог</span>
                        </div>
                    </div>
                    <div className="room__info__wrapper">
                        <img src={panoramicModeSvg} alt="avatar" />
                        <p>Для эффективной работы переведите устройство в панорамный режим</p>
                    </div>
                </div>
            </main>

            {showCanvasToolbar && (
                <div className="room__toolbar">
                    {/* <button
                        className={classNames(
                            'room__toolbar__item',
                            isEnabledAudioTrack ? 'active' : ''
                        )}
                        onClick={onSetIsEnabledAudioTrack}
                    >
                        <img
                            src={isEnabledAudioTrack ? microIconSvg : microIconDisabledSvg}
                            alt="microphone"
                        />
                    </button> */}
                    {/* <button
                        className={classNames(
                            'room__toolbar__item',
                            myStreamType === 'video' ? '' : 'active'
                        )}
                        onClick={toggleStreamType}
                    >
                        <img src={shareDisplaySvg} alt="share-display" />
                    </button> */}
                    {/* <button
                        className={classNames('room__toolbar__item', 'active')}
                        onClick={clearCanvas}
                    >
                        <img src={clearDeskSvg} alt="clear-board" />
                    </button> */}
                    {!roomtrain && (
                        <button
                            className={classNames('room__toolbar__item', 'active')}
                            onClick={checkForAccidentalButtonPresses}
                        >
                            <img src={arbitrationSvg} alt="clear-board" />
                        </button>
                    )}
                    <button
                        className={classNames('room__toolbar__item', 'active')}
                        onClick={openModalMediaSettings}
                    >
                        <img src={deviceSettingsIcon} alt="clear-board" />
                    </button>
                    {!roomtrain && (
                        <button
                            className={classNames('room__toolbar__item', 'active')}
                            onClick={openFilesModal}
                        >
                            <img src={cloudUploadIcon} alt="leave room" />
                        </button>
                    )}
                    {!roomtrain && (
                        <button
                            className={classNames('room__toolbar__item', 'active')}
                            onClick={onChatLeave}
                        >
                            <img src={leaveRoomSvg} alt="leave room" />
                        </button>
                    )}
                </div>
            )}

            <footer className="workspace__footer">
                {/* <button
                    className={classNames(
                        'workspace__footer__item',
                        activeFooterItem === TabBarItem.TEACHER ? 'active' : ''
                    )}
                    onClick={() => switchTab(TabBarItem.TEACHER)}
                >
                    <img src={graduationСap} alt="Учитель" />
                    <p>Учитель</p>
                </button> */}
                <button
                    // disabled={!isActiveChatMessenger}
                    className={classNames(
                        'workspace__footer__item',
                        // activeFooterItem === footerItems.BOARD ? 'active' : ''
                        activeFooterItem === TabBarItem.BOARD ? 'active' : ''
                    )}
                    onClick={() => switchTab(TabBarItem.BOARD)}
                    // onClick={onSetIsActiveChatMessenger}
                >
                    <img src={boardFooterBuuton} alt="Доска" />
                    <p>Доска</p>
                </button>
                <button
                    className={classNames(
                        'workspace__footer__item',
                        activeFooterItem === TabBarItem.CAMERA ? 'active' : ''
                    )}
                    onClick={() => switchTab(TabBarItem.CAMERA)}
                >
                    <img src={cameraFooterBuuton} alt="Камера" />
                    <p>Камера</p>
                </button>
                <button
                    // disabled={isActiveChatMessenger}
                    className={classNames(
                        'workspace__footer__item',
                        // activeFooterItem === footerItems.CHAT ? 'active' : ''
                        activeFooterItem === TabBarItem.CHAT ? 'active' : ''
                    )}
                    onClick={() => switchTab(TabBarItem.CHAT)}
                    // onClick={onSetIsActiveChatMessenger}
                >
                    <img src={chatFooterBuuton} alt="Чат" />
                    <p>Чат</p>
                </button>
            </footer>
            {isArbitration && (
                <ModalArbitration
                    title="Предупреждение"
                    content={
                        loading ? (
                            <div className="modal__loading">
                                <img src={loadingIcon} alt="loading icon" />
                            </div>
                        ) : (
                            <div className="modal__alert">
                                <div className="modal__alertText">
                                    <p>
                                        Нажатая кнопка "Экстренное завершение" запустит Арбитраж,
                                        <br />
                                        потраченные вами минуты будут заморожены, <br /> а педагог
                                        не получит денег до окончания разбирательства. <br />
                                        Продолжить?
                                    </p>
                                </div>
                                <div className="modal__buttons">
                                    <Button type="secondary" onClick={resetArbitration}>
                                        Нет
                                    </Button>
                                    <Button type="secondary" onClick={onExpressChatLeave}>
                                        Да
                                    </Button>
                                </div>
                            </div>
                        )
                    }
                    close={resetArbitration}
                />
            )}
            {showModalMediaDevice && <ModalMediaResolution />}

            {clientError && (
                <ModalError
                    title={clientError.title}
                    content={
                        <div className="modal__alert">
                            <div className="modal__alertText">
                                <p>{clientError.text}</p>
                            </div>
                            {clientError.canClose && (
                                <div className="modal__buttons">
                                    <Button type="secondary" onClick={closeClientErrorModal}>
                                        Закрыть
                                    </Button>
                                </div>
                            )}
                        </div>
                    }
                    close={clientError.canClose ? closeClientErrorModal : null}
                />
            )}

            {clientNotice && (
                <ClientNotice
                    title={clientNotice.title}
                    text={clientNotice.text}
                    close={closeClientNotice}
                />
            )}

            {!roomtrain && (
                <WorkspaceService
                    setChatDetails={setChatDetails}
                    setBbbUrl={setBbbUrl}
                    setTeacherAvtar={setTeacherAvtar}
                    setError={setError}
                    setInfoMessagesInChat={setInfoMessagesInChat}
                    setOnStart={setOnStart}
                />
            )}
            {!roomtrain && <MessangerService />}
            <Modal
                isOpen={fileModalVisible}
                // onAfterOpen={afterOpenModal}
                onRequestClose={() => setFileModalVisible(false)}
                style={{
                    overlay: {
                        backgroundColor: '#00000099',
                        display: 'flex',
                        justifyContent: 'center'
                    },
                    content: {
                        left: 'unset',
                        right: 'unset',
                        // bottom: 'unset',
                        borderWidth: 0,
                        borderRadius: 16,
                        padding: 20,
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'visible',
                        width: '100%',
                        maxWidth: 500
                    }
                }}
                ariaHideApp={false}
                // className="files-modal"
            >
                <button
                    style={{
                        backgroundColor: 'transparent',
                        outline: 'none',
                        border: 'none',
                        padding: 10,
                        position: 'absolute',
                        right: -60,
                        top: 5,
                        cursor: 'pointer'
                    }}
                    onClick={() => setFileModalVisible(false)}
                >
                    <CloseIcon fontSize="large" htmlColor="#fff" />
                </button>
                {/* <div className="messanger-container" style={{ flex: 1 }}> */}
                    <Messanger />
                {/* </div> */}
            </Modal>
        </div>
    )
}

export default Workspace
