import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import axios from 'axios'

import { setOrder } from '../../store/actions/order'
import { setUser } from '../../store/actions/users'

import { selectOrder } from '../../store/selectors/order'

import useRoomContext from '../../context/roomContext'

import burgerMenu from '../../assets/icons/burger-menu.svg'
import loadingIcon from '../../assets/icons/loading.svg'

import Header from '../../components/Header'
import ModalArbitration from '../../components/ModalArbitration'

import './ComplaintForm.scss'
import { selectUser } from '../../store/selectors/users'
import Button from '@components/UI/button'
import Checkbox from '@components/UI/checkbox'

function ComplaintForm() {
    const dispatch = useDispatch()

    const { token, orderId } = useParams()

    const { socket } = useRoomContext()

    const order = useSelector(selectOrder)

    const user = useSelector(selectUser)

    const [comment, setComment] = useState('')
    const [manners, setManners] = useState(false)
    const [methodology, setMethodology] = useState(false)
    const [appearance, setAppearance] = useState(false)
    const [complaintFormSent, setComplaintForm] = useState(false)

    const [loading, setLoading] = useState(false)

    const commentLength = comment.length
    const isDisabled = commentLength < 30

    const onComplaintFormSend = async () => {
        try {
            setLoading(true)

            const config = {
                method: 'post',
                url: `${process.env.REACT_APP_API_URL}/public/arbitration/${orderId}/appeal`,
                data: { comment, manners, methodology, appearance },
                headers: {
                    'x-access-token': token
                }
            }

            const { data } = await axios(config)

            if (data?.success) {
                user?.role?.id === 1 ? setComplaintForm(true) : onGoToProfileInfo()
            }
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    const onGoToCreateOrder = () => {
        window.location.href = 'https://my5plus.online/profile/create/order'
    }

    const onGoToProfileInfo = () => {
        window.location.href = `https://my5plus.online/profile/info`
    }

    const fetchOrder = async () => {
        try {
            const config = {
                method: 'post',
                url: `${process.env.REACT_APP_API_URL}/public/orders/list`,
                data: {
                    filter: {
                        id: orderId
                    }
                },
                headers: {
                    'x-access-token': token
                }
            }

            const { data } = await axios(config)

            if (data?.success) {
                const order = data.result[0]

                if (order) {
                    dispatch(setOrder(order))
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        socket.emit('public-user-get', {}, ({ result }) => {
            dispatch(setUser(result))
        })
    }, [dispatch, socket])

    useEffect(() => {
        if (!order) {
            fetchOrder()
        }
    }, []) // eslint-disable-line

    return (
        <div className="wrap">
            <Header
                menuButton={
                    <button className="complaint-header-burger">
                        <img src={burgerMenu} alt="burger" />
                    </button>
                }
                className="complaint-header header"
            />
            <div className="complaint-title">
                <h1>Жалобная форма</h1>
            </div>
            <div className="complaint-content">
                {!loading ? (
                    <div className="complaint-form">
                        <div className="complaint-form__title">
                            <p>Причина открытия Арбитража</p>
                        </div>
                        <div className="complaint-form__checkboxes">
                            <div className="complaint-form__checkbox">
                                <Checkbox onInput={setManners} /> <span>Манера общения</span>
                            </div>
                            {user?.role?.id === 1 && (
                                <div className="complaint-form__checkbox">
                                    <Checkbox onInput={setMethodology} />
                                    <span>Методика преподавания</span>
                                </div>
                            )}
                            <div className="complaint-form__checkbox">
                                <Checkbox onInput={setAppearance} /> <span>Внешний вид</span>
                            </div>
                        </div>
                        <div className="complaint-form__textarea">
                            <p>
                                Опишите причину открытия арбитража (от 30 до 500 знаков с
                                пробелами):
                            </p>
                            <textarea
                                placeholder="Комментарий для администратора"
                                className="complaint-form__textarea"
                                value={comment}
                                maxLength="500"
                                onChange={(e) => setComment(e.target.value)}
                            />
                            <span className="complaint-form__textarea-count">
                                {commentLength}/500
                            </span>
                        </div>
                        <div className="complaint-form__button">
                            <Button onClick={onComplaintFormSend} disabled={isDisabled}>
                                Отправить жалобу
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className="complaint-form__loading">
                        <img src={loadingIcon} alt="loading icon" />
                    </div>
                )}
            </div>

            {complaintFormSent && (
                <ModalArbitration
                    title="Жалобная форма отправлена"
                    content={
                        <div className="modal__alert">
                            <div className="modal__alertText">
                                <p>Хотите сделать новый заказ?</p>
                            </div>
                            <div className="modal__buttons">
                                <Button type="secondary" onClick={onGoToProfileInfo}>
                                    Нет
                                </Button>
                                <Button type="secondary" onClick={onGoToCreateOrder}>
                                    Да
                                </Button>
                            </div>
                        </div>
                    }
                />
            )}
        </div>
    )
}

export default ComplaintForm
