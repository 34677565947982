import React, { useState, useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom' // eslint-disable-line

import axios from 'axios'
import io from 'socket.io-client'
import classNames from 'classnames'
// import fileDownload from 'js-file-download'
import moment from 'moment'
// import FileSaver from 'file-saver'
import 'moment/locale/ru'

import { setOrder } from '../../store/actions/order'
import { setUser } from '../../store/actions/users'
import { setClientError, setOnGoBackToPublic } from '../../store/actions/clientError'

import { selectUser } from '../../store/selectors/users'
import { selectOrder, selectOrderComment, selectOrderGrade } from '../../store/selectors/order' // eslint-disable-line
import { selectClientError } from '../../store/selectors/clientError'

import useRoomContext from '../../context/roomContext'

import star from '../../assets/icons/star.svg'
import callback from '../../assets/icons/qna-icon.svg'

import avatar from '../../assets/icons/avatar.svg'
import burgerMenu from '../../assets/icons/burger-menu.svg'
import ratingStar from '../../assets/icons/rating-star.svg'
import lessonRecord from '../../assets/images/lesson-record.png'
import boardRecord from '../../assets/images/board-record.png'
import chatRecord from '../../assets/images/chat-record.png'
import loading from '../../assets/icons/loading.svg'

import Header from '../../components/Header'
import Rating from '@mui/material/Rating'
import InfoModal from '../../components/ModalError'
import ModalArbitration from '../../components/ModalArbitration'

import './CompletedLesson.scss'
import Button from '@components/UI/button'

moment.locale('ru')

function CompletedLesson() {
    const dispatch = useDispatch()
    const { token, orderId } = useParams()

    const { socket } = useRoomContext()

    const navigate = useNavigate()

    const [comment, setComment] = useState('')
    const [grade, setGrade] = useState(null)
    const [isRated, setIsRated] = useState(false)
    const [isMobileRated, setIsMobileRated] = useState(false)
    const [isArbitration, setIsArbitration] = useState(false)
    const [loaderOrderComplited, setLoaderOrderComplited] = useState(true)
    const [loaderClassRecording, setLoaderClassRecording] = useState(false)
    const [loaderBoardsRecording, setLoaderBoardsRecording] = useState(false)
    const [loaderChatRecording, setLoaderChatRecording] = useState(false)

    const order = useSelector(selectOrder)

    const user = useSelector(selectUser)

    const infoModal = useSelector(selectClientError)

    const date = order?.chat?.start ? moment(order?.chat?.start).format('DD MMMM YYYY') : null
    const startTime = order?.chat?.start ? moment(order?.chat?.start).format('HH mm') : null
    const endTime = order?.chat?.end ? moment(order?.chat?.end).format('HH mm') : null
    const duration = order?.chat?.end
        ? moment(order?.chat?.end).diff(moment(order?.chat?.start), 'minutes')
        : null

    const commentLength = comment.length
    const commentLengthLimit = commentLength > 500
    const isDisabled = grade === null || commentLengthLimit

    const goToRecord = async () => {
        setLoaderClassRecording(true)
        try {
            const config = {
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}/public/orders/records/${orderId}`,
                headers: {
                    'x-access-token': token
                }
            }

            const res = await axios(config)
            const result = res.data.result[0]

            if (result?.state === 'processing') {
                alert('Запись обрабатывается')
            } else if (result?.url) {
                window.open(result.url, '_blank')
            } else if (result) {
                alert('Запись обрабатывается')
            } else {
                alert('Запись не найдена')
            }
        } catch (e) {
            console.log(e)
        } finally {
            setLoaderClassRecording(false)
        }
    }
    // const onChatCanvasUpload = () => {
    //     setLoaderBoardsRecording(true)
    //     try {
    //         const config = {
    //             method: 'get',
    //             url: `${process.env.REACT_APP_API_URL}/public/video/canvas/${orderId}`,
    //             headers: {
    //                 'x-access-token': token
    //             },
    //             responseType: 'blob'
    //         }

    //         axios(config)
    //             .then(function ({ data }) {
    //                 const blob = new Blob([data], { type: 'application/zip' })
    //                 FileSaver.saveAs(blob, 'data.zip')
    //                 setLoaderBoardsRecording(false)
    //             })
    //             .catch(function (error) {
    //                 console.log(error, 'error')
    //             })
    //             .finally(() => {
    //                 setLoaderBoardsRecording(false)
    //             })
    //     } catch (e) {
    //         console.log(e)
    //     }
    // }

    // const onChatSave = () => {
    //     setLoaderChatRecording(true)
    //     const config = {
    //         method: 'get',
    //         url: `${process.env.REACT_APP_API_URL}/public/orders/chat/log/${orderId}`,
    //         headers: {
    //             'x-access-token': token
    //         }
    //     }
    //     axios(config)
    //         .then(async ({ data }) => {
    //             await fileDownload(data, 'Чат.txt')
    //         })
    //         .finally(() => {
    //             setLoaderChatRecording(false)
    //         })
    // }

    const checkRateForArbitration = () => {
        if (grade > 2) {
            onRateSend()
        } else {
            setIsArbitration(true)
        }
    }

    const resetArbitration = () => {
        setIsArbitration(false)
    }

    const onRateSend = async () => {
        try {
            const config = {
                method: 'patch',
                url: `${process.env.REACT_APP_API_URL}/public/orders/rate/${orderId}`,
                data: { grade, comment },
                headers: {
                    'x-access-token': token
                }
            }

            const { data } = await axios(config)

            if (data?.success) {
                setIsRated(true)
                setIsMobileRated(true)
                setGrade(grade)
                setComment(comment)

                if (isArbitration) {
                    await sentInArbitrationByGrade()
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    const sentInArbitrationByGrade = async () => {
        try {
            const config = {
                method: 'post',
                url: `${process.env.REACT_APP_API_URL}/public/arbitration/${orderId}`,
                data: { isByEmergencyExit: false },
                headers: {
                    'x-access-token': token
                }
            }

            const { data } = await axios(config)

            if (data?.success) {
                setIsArbitration(false)
                navigate(`/room/${orderId}/${token}/complaint`)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const onGoBackToChat = () => {
        setOnGoBackToPublic(true)
        window.location.href = 'https://my5plus.online/profile/support'
        //navigate('https://public.edu-cator.ru/profile/info')
    }
    const onGoBackToProfile = () => {
        setOnGoBackToPublic(true)
        window.location.href = 'https://my5plus.online/profile/info'
        //navigate('https://public.edu-cator.ru/profile/info/profile/chat')
    }
    const onGoBackToLesson = () => {
        setOnGoBackToPublic(true)
        window.location.href = `https://my5plus.online/profile/classes/orders/${orderId}`
        //navigate('https://public.edu-cator.ru/profile/info/profile/chat')
    }

    // useLayoutEffect(() => {
    //     socketRef.current = io.connect(process.env.REACT_APP_RTC_URL, {
    //         extraHeaders: {
    //             'x-access-token': token
    //         }
    //     })
    // }, [token])
    const closeInfoModal = () => {
        dispatch(setClientError(null))
    }

    const downloadFiles = async () => {
        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/public/chat/media/archive`,
            data: {
                orderId: orderId
            },
            headers: {
                'x-access-token': token
            },
            responseType: 'arraybuffer'
        }
        try {
            const resp = await axios(config)
            if (resp?.status === 200 && resp?.data) {
                var a = document.createElement('a')
                document.body.appendChild(a)
                a.style = 'display:none'
                var url = window.URL.createObjectURL(new Blob([resp.data]))
                a.href = url
                a.download = 'download.zip'
                a.click()
                window.URL.revokeObjectURL(url)
                a.remove()
            }
        } catch (error) {
            console.warn(error)
            dispatch(
                setClientError({
                    title: 'Ошибка',
                    text: `Ошибка получения архива с сервера!`,
                    canClose: true
                })
            )
        }
    }

    const lessonWithoutHomework = async () => {
        try {
            setLoaderOrderComplited(true)
            const { data } = await axios.patch(
                `${process.env.REACT_APP_API_URL}/public/homework/hasHomework`,
                {
                    orderId: orderId
                },
                {
                    headers: { 'x-access-token': token }
                }
            )
            if (data?.success) {
                dispatch(setOrder({ ...order, hasHomework: false }))
                dispatch(
                    setClientError({
                        title: 'Домашнее задание',
                        text: `Для данного занятия успешно установлен статус "Урок без ДЗ"`,
                        canClose: true,
                        withoutHomework: true
                    })
                )
            }
        } catch (error) {
            console.warn(error)
        } finally {
            setLoaderOrderComplited(false)
        }
    }

    useEffect(() => {
        socket.emit('public-user-get', {}, async ({ result }) => {
            const localUser = result
            if (localUser?.attributes?.avatar) {
                try {
                    const resp = await axios.get(
                        `${process.env.REACT_APP_API_URL}/public/user/avatar/${localUser.id}`,
                        {
                            headers: { 'x-access-token': token },
                            responseType: 'arraybuffer'
                        }
                    )

                    if (resp && resp.status === 200) {
                        const buffer = Buffer.from(resp.data)
                        const base64String = buffer.toString('base64')
                        localUser.avatar = `data:image/jpg;base64,${base64String}`
                    }
                } catch (e) {
                    console.log(e)
                }
            }
            dispatch(setUser(localUser))
            // console.log('user: ', localUser) for development
            try {
                const { data } = await axios.post(
                    `${process.env.REACT_APP_API_URL}/public/orders/list`,
                    {
                        filter: {
                            id: orderId
                        }
                    },
                    {
                        headers: { 'x-access-token': token }
                    }
                )
                const order = data.result[0]
                if (localUser.id === order?.teacherId) {
                    order.teacher.avatar = localUser.avatar
                } else if (order?.teacher?.attributes?.avatar) {
                    try {
                        const resp = await axios.get(
                            `${process.env.REACT_APP_API_URL}/public/user/avatar/${order.teacher.id}`,
                            {
                                headers: { 'x-access-token': token },
                                responseType: 'arraybuffer'
                            }
                        )

                        if (resp && resp.status === 200) {
                            const buffer = Buffer.from(resp.data)
                            const base64String = buffer.toString('base64')
                            order.teacher.avatar = `data:image/jpg;base64,${base64String}`
                        }
                    } catch (e) {
                        console.log(e)
                    }
                }
                if (order) {
                    dispatch(setOrder(order))
                    // console.log('order: ', order) for development
                }
                if (order.arbitration && order?.arbitration?.initiatorId === localUser?.id) {
                    navigate(`/room/${orderId}/${token}/complaint`)
                }
                if (order.grade && order.resultComment) {
                    setGrade(order.grade)
                    setComment(order.resultComment)
                    setIsRated(true)
                    setIsMobileRated(true)
                } else {
                    setIsMobileRated(false)
                    setIsRated(false)
                }
                if (order.statusId === 4 && [1, 2].includes(order.customTypeId)) {
                    dispatch(
                        setClientError({
                            title: `${
                                order.customTypeId === 1 ? 'Первая' : 'Вторая'
                            } диагностическая сессия завершена`,
                            text: 'Пожалуйста перейдите в заказ для выбора дальнейших действий',
                            type: 'DIAGNOSTIC_SESSION',
                            canClose: true
                        })
                    )
                }
                if (
                    localUser?.role?.id === 3 &&
                    order?.teachingTypeId === 1 &&
                    order?.isTrial === false &&
                    order.hasHomework === null
                ) {
                    dispatch(
                        setClientError({
                            title: `Занятие по предмету "${order?.subject?.name}" завершено`,
                            text: 'Пожалуйста перейдите в заказ для выбора дальнейших действий',
                            canClose: true,
                            homework: true
                        })
                    )
                }
            } catch (e) {
                console.log(e)
            } finally {
                setLoaderOrderComplited(false)
            }
        })
    }, [socket]) // eslint-disable-line

    useEffect(() => {
        socket.on('server-notifications-send', async ({ type, data }) => {
            if (data) {
                const { statusId } = JSON.parse(data)
                if ([4, 5].includes(statusId) && [15, 42].includes(type.id)) {
                    const fetchOrder = async () => {
                        try {
                            const { data } = await axios.post(
                                `${process.env.REACT_APP_API_URL}/public/orders/list`,
                                {
                                    filter: {
                                        id: orderId
                                    }
                                },
                                {
                                    headers: { 'x-access-token': token }
                                }
                            )
                            const order = data.result[0]

                            if (order?.teacher?.attributes?.avatar) {
                                try {
                                    const resp = await axios.get(
                                        `${process.env.REACT_APP_API_URL}/public/user/avatar/${order.teacher.id}`,
                                        {
                                            headers: { 'x-access-token': token },
                                            responseType: 'arraybuffer'
                                        }
                                    )

                                    if (resp && resp.status === 200) {
                                        const buffer = Buffer.from(resp.data)
                                        const base64String = buffer.toString('base64')
                                        order.teacher.avatar = `data:image/jpg;base64,${base64String}`
                                    }
                                } catch (e) {
                                    console.log(e)
                                }
                            }

                            if (order) {
                                dispatch(setOrder(order))
                            }

                            console.log('order: ', order)

                            if (order?.arbitration?.initiatorId === user?.id) {
                                navigate(`/room/${orderId}/${token}/complaint`)
                            }

                            if (order.grade && order.resultComment) {
                                setGrade(order.grade)
                                setComment(order.resultComment)
                                setIsRated(true)
                                setIsMobileRated(true)
                            } else {
                                setIsMobileRated(false)
                                setIsRated(false)
                            }
                        } catch (e) {
                            console.log(e)
                        }
                    }

                    fetchOrder()
                }
            }
        })
    }, [socket]) // eslint-disable-line

    return loaderOrderComplited ? (
        <div className="completed-lesson-loading">
            <img src={loading} alt="loading" />
        </div>
    ) : (
        <div className="wrap">
            <Header
                menuButton={
                    <button className="header__profile-burger completed-lesson-header-burger">
                        <img src={burgerMenu} alt="burger" />
                    </button>
                }
                className="completed-lesson-header header"
            />
            {order && (
                <div className="completed-lesson-title">
                    <h1>{order?.statusId === 4 ? 'Завершенное' : 'Отмененное'} занятие</h1>
                </div>
            )}
            {order && (
                <div className="completed-lesson">
                    <div className="completed-lesson__info">
                        <div className="completed-lesson__info-profile">
                            <div className="completed-lesson__info-profile__avatar">
                                <img src={order?.teacher?.avatar || avatar} alt="аватарка" />
                            </div>
                            <p>
                                <span>{order?.teacher?.attributes?.lastName}</span>
                                {` `}
                                {order?.teacher?.attributes?.firstName}
                                {` `}
                                {order?.teacher?.attributes?.middleName}
                            </p>
                        </div>
                        <div className="completed-lesson__info-desc">
                            <div>
                                <p>{order && order.subject ? 'Предмет' : 'Направление'} </p>
                                {date && <p>Дата</p>}
                                {startTime && <p>Время начала</p>}
                                {endTime && <p>Время окончания</p>}
                                {duration && <p>Длительность</p>}
                            </div>
                            <div>
                                <p>
                                    {order && order.subject
                                        ? order.subject.name
                                        : order.group && order.group.logopedisticAttributes
                                        ? 'Логопед'
                                        : order.group && order.group.preschoolAttributes
                                        ? 'Подготовка к школе'
                                        : 'Не указан'}
                                </p>
                                {date && <p>{date}</p>}
                                {startTime && <p>{startTime}</p>}
                                {endTime && <p>{endTime}</p>}
                                {duration && <p>{duration} минут</p>}
                            </div>
                        </div>
                        {[1, 2].includes(user?.role?.id) &&
                            order?.statusId === 4 &&
                            !isMobileRated &&
                            !order?.arbitration && (
                                <div className="completed-lesson__info-button">
                                    <Button onClick={() => setIsMobileRated(!isMobileRated)}>
                                        Оценить занятие
                                    </Button>
                                </div>
                            )}
                    </div>
                    {order?.statusId === 4 && (
                        <div className="completed-lesson__records">
                            <div className="completed-lesson__records-lesson" onClick={goToRecord}>
                                {!loaderClassRecording ? (
                                    <img src={lessonRecord} alt="кнопка записи занятия" />
                                ) : (
                                    <img src={loading} alt="загрузка" />
                                )}
                                <p>Запись занятия</p>
                            </div>
                            {/* <div className="completed-lesson__records-board">
                                {!loaderBoardsRecording ? (
                                    <img
                                        src={boardRecord}
                                        alt="запись доски"
                                        onClick={onChatCanvasUpload}
                                    />
                                ) : (
                                    <img src={loading} alt="загрузка" />
                                )}
                                <p>Запись доски</p>
                            </div> */}
                        </div>
                    )}
                    <div className="completed-lesson__chat">
                        {/* {order?.statusId === 4 && (
                            <div className="completed-lesson__chat-record">
                                {!loaderChatRecording ? (
                                    <img src={chatRecord} alt="запись чата" onClick={onChatSave} />
                                ) : (
                                    <img src={loading} alt="загрузка" />
                                )}
                                <p>Запись чата</p>
                            </div>
                        )} */}
                        <div onClick={onGoBackToChat} className="completed-lesson__chat-callback">
                            <div>
                                <img src={callback} alt="обратная связь" />
                                <span>Обратная связь</span>
                            </div>
                            <p>(написать администратору сервиса)</p>
                        </div>
                        <div className="completed-lesson__chat_back-button">
                            <button onClick={onGoBackToProfile}>Перейти в личный кабинет</button>
                        </div>
                        <div className="completed-lesson__chat_back-button">
                            <button onClick={downloadFiles}>Скачать архив файлов</button>
                        </div>
                        {user?.role?.id === 4 && [1, 2].includes(order?.customTypeId) && (
                            <div className="completed-lesson__chat_back-button">
                                <button onClick={onGoBackToLesson}>
                                    Перейти в заказ для выбора дальнейших действий
                                </button>
                            </div>
                        )}
                        {user?.role?.id === 3 &&
                            order?.teachingTypeId === 1 &&
                            order?.isTrial === false &&
                            order.hasHomework === null && (
                                <div className="completed-lesson__chat_back-button">
                                    <button onClick={onGoBackToLesson}>
                                        Перейти к установке ДЗ
                                    </button>
                                    <button
                                        style={{ margin: '1rem 0 0' }}
                                        onClick={lessonWithoutHomework}
                                    >
                                        Урок без ДЗ
                                    </button>
                                </div>
                            )}
                    </div>
                    {!user ||
                    !order ||
                    [3, 4, 5].includes(user?.role?.id) ||
                    order?.statusId === 5 ||
                    order?.arbitration ? null : !isRated ? (
                        <div
                            className={classNames(
                                'completed-lesson__rating-wrap',
                                isMobileRated ? 'mobile-active' : ''
                            )}
                        >
                            <div className="completed-lesson__rating">
                                <div className="completed-lesson__rating-title">
                                    <p>Пожалуйста оцените занятие</p>
                                </div>
                                <div className="completed-lesson__rating-stars">
                                    <Rating
                                        name="customized-color"
                                        defaultValue={0}
                                        value={grade}
                                        onChange={(_, newGrade) => {
                                            setGrade(newGrade)
                                        }}
                                        icon={
                                            <img
                                                src={star}
                                                alt="звездочка"
                                                className={`${grade}`}
                                            />
                                        }
                                        emptyIcon={<img src={ratingStar} alt="звездочка" />}
                                    />
                                </div>
                                <div className="completed-lesson__rating-textarea">
                                    <textarea
                                        placeholder="Комментарий для преподавателя"
                                        className="completed-lesson__rating-textarea"
                                        value={comment}
                                        onChange={(e) => setComment(e.target.value)}
                                    />
                                    <span
                                        className={classNames(
                                            'completed-lesson__rating-textarea-count',
                                            commentLengthLimit ? 'error' : ''
                                        )}
                                    >
                                        {commentLength}/500
                                        {commentLengthLimit}
                                    </span>
                                </div>
                                {[1, 2].includes(user?.role?.id) && order?.statusId === 4 && (
                                    <div className="completed-lesson__rating-button">
                                        <Button
                                            onClick={
                                                user?.role?.id === 2
                                                    ? onRateSend
                                                    : checkRateForArbitration
                                            }
                                            disabled={isDisabled}
                                        >
                                            Оценить занятие
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div
                            className={classNames(
                                'completed-lesson__rated',
                                isMobileRated ? 'mobile-active' : ''
                            )}
                        >
                            <div className="completed-lesson__rated-title">
                                <p>Ваша оценка</p>
                            </div>
                            <div className="completed-lesson__rated-stars">
                                <Rating
                                    name="customized-color"
                                    defaultValue={grade}
                                    value={grade}
                                    readOnly
                                    icon={<img src={star} alt="звездочка" />}
                                    emptyIcon={<img src={ratingStar} alt="звездочка" />}
                                />
                            </div>
                            <div className="completed-lesson__rated-text">{comment}</div>
                        </div>
                    )}

                    {order?.arbitration && (
                        <div className="completed-lesson__notice-wrap">
                            <div className="completed-lesson__notice">
                                <p className="completed-lesson__notice-title">
                                    Занятие завершено по причине нажатия кнопки
                                    <br className="completed-lesson__notice-br" />
                                    "Экстренный выход"
                                    {[3, 4, 5].includes(order?.arbitration?.initiator?.roleId)
                                        ? ' педагогом'
                                        : ' учеником'}
                                    .
                                    <br />
                                    Детали у администратора.
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            )}

            {isArbitration && (
                <ModalArbitration
                    title="Предупреждение"
                    content={
                        <div className="modal__alert">
                            <div className="modal__alertText">
                                <p>
                                    Низкая оценка запустит Арбитраж, <br />
                                    потраченные вами минуты будут заморожены, <br /> а педагог не
                                    получит денег до окончания разбирательства. <br /> Продолжить?
                                </p>
                            </div>
                            <div className="modal__buttons">
                                <Button type="secondary" onClick={resetArbitration}>
                                    Нет
                                </Button>
                                <Button type="secondary" onClick={onRateSend}>
                                    Да
                                </Button>
                            </div>
                        </div>
                    }
                    close={resetArbitration}
                />
            )}

            {infoModal && (
                <InfoModal
                    title={infoModal.title}
                    content={
                        <div className="modal__alert">
                            <div className="modal__alertText">
                                <p>{infoModal.text}</p>
                            </div>
                            {infoModal.homework ? (
                                <div className="modal__buttons">
                                    <Button type="secondary" onClick={onGoBackToLesson}>
                                        Перейти к установке ДЗ
                                    </Button>
                                    <Button type="secondary" onClick={lessonWithoutHomework}>
                                        Урок без ДЗ
                                    </Button>
                                    <Button type="secondary" onClick={closeInfoModal}>
                                        Закрыть
                                    </Button>
                                </div>
                            ) : infoModal.withoutHomework ? (
                                <div className="modal__buttons">
                                    <Button type="secondary" onClick={closeInfoModal}>
                                        Закрыть
                                    </Button>
                                </div>
                            ) : (
                                <div className="modal__buttons">
                                    <Button type="secondary" onClick={onGoBackToLesson}>
                                        Перейти в заказ
                                    </Button>
                                    <Button type="secondary" onClick={closeInfoModal}>
                                        Закрыть
                                    </Button>
                                </div>
                            )}
                        </div>
                    }
                    close={closeInfoModal}
                />
            )}
        </div>
    )
}

export default CompletedLesson
