import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'
import Room from './components/room'
import { store } from './store'
import './styles/app.scss'

import Workspace from './routes/workspace'
import CompletedLesson from './routes/CompletedLesson'
import ChatRecord from './routes/ChatRecord'
import LessonRecord from './routes/LessonRecord'
import ComplaintForm from './routes/ComplaintForm'

function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <Routes>
                    <Route path="/room/:orderId/:token" element={<Room />}>
                        <Route index element={<Workspace />} />
                        <Route path="completed" exact element={<CompletedLesson />} />
                        <Route path="completed/chat-record" exact element={<ChatRecord />} />
                        <Route path="completed/lesson-record" exact element={<LessonRecord />} />
                        <Route
                            path="complaint"
                            exact
                            element={<ComplaintForm />}
                        />
                    </Route>
                    <Route path="/roomtrain/" exact element={<Workspace roomtrain />} />
                </Routes>
            </BrowserRouter>
        </Provider>
    )
}

export default App
