import React, { useState } from 'react' // eslint-disable-line
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import axios from 'axios'

import useRoomContext from '../../context/roomContext'

import { selectChatId, selectIsActiveChatMessanger } from '../../store/selectors/chats'
import { setIsActiveChatMessenger } from '../../store/actions/chats'
import { selectIsReceivedMessage } from '../../store/selectors/messages'
import { setModal, setModalService } from '../../store/actions/mediaDevice'

import ExpressLeave from '@mui/icons-material/Warning'
import ReactTooltip from 'react-tooltip'

import leave from '../../assets/icons/leave.svg'
import messenger from '../../assets/icons/messenger.svg'
import messengerActive from '../../assets/icons/messenger-active.svg'
import profile from '../../assets/icons/profile.svg'
import deviceSettingsIcon from '../../assets/icons/device-settings.svg'
import cloudUploadIcon from '../../assets/icons/cloud-upload.svg'
import loadingIcon from '../../assets/icons/loading.svg'

import ModalArbitration from '../../components/ModalArbitration'

import './Header.scss'
import Button from '@components/UI/button'
import classNames from 'classnames'

function Toolbar({ iframeRef, roomtrain, unreadMsg, openFilesModal }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { orderId, token } = useParams()
    const { socket } = useRoomContext()

    const chatId = useSelector(selectChatId)
    const isActiveChatMessenger = useSelector(selectIsActiveChatMessanger)
    const isReceivedMessage = useSelector(selectIsReceivedMessage)

    const [isArbitration, setIsArbitration] = useState(false)

    const [loading, setLoading] = useState(false)

    const onChatLeave = () => {
        if (window.confirm('Вы действительно хотите выйти из комнаты?')) {
            socket.emit('public-chats-leave', { chatId, type: 'classroom-toolbar' }, (res) => {
                window.dataLayer.push({
                    event: 'сlientsuccessexit',
                    orderId
                })
                navigate(`/room/${orderId}/${token}/completed`)
            })
        }
    }
    const onExpressChatLeave = async () => {
        socket.emit('public-chats-leave', { chatId, type: 'classroom-toolbar-express' }, (res) => {
            console.log(res)
        })

        try {
            setLoading(true)

            const config = {
                method: 'post',
                url: `${process.env.REACT_APP_API_URL}/public/arbitration/${orderId}`,
                data: { isByEmergencyExit: true },
                headers: {
                    'x-access-token': token
                }
            }

            const { data } = await axios(config)

            if (data?.success) {
                setIsArbitration(false)
            }
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    const onSetIsActiveChatMessenger = () => {
        if (!iframeRef.current) return

        iframeRef.current.contentWindow.postMessage(
            isActiveChatMessenger ? 'hide-chat' : 'show-chat',
            '*'
        )
        dispatch(setIsActiveChatMessenger(!isActiveChatMessenger))
    }

    const openModalMediaSettings = () => {
        dispatch(setModalService('Продолжить занятие'))
        dispatch(setModal(true))
    }

    const checkForAccidentalButtonPresses = () => {
        setIsArbitration(true)
    }

    const resetArbitration = () => {
        setIsArbitration(false)
    }

    if (roomtrain) {
        return (
            <div className="toolbar">
                {isActiveChatMessenger ? (
                    <button onClick={onSetIsActiveChatMessenger}>
                        <img
                            src={profile}
                            alt="profile icon"
                            data-tip
                            data-for="activeMessengerTip"
                        />
                        <ReactTooltip id="activeMessengerTip" place="bottom" effect="solid">
                            Скрыть чат
                        </ReactTooltip>
                    </button>
                ) : (
                    <button onClick={onSetIsActiveChatMessenger}>
                        <img
                            src={unreadMsg ? messengerActive : messenger}
                            alt="messenger icon"
                            data-tip
                            data-for="messengerTip"
                        />
                        <ReactTooltip id="messengerTip" place="bottom" effect="solid">
                            Перейти в чат
                        </ReactTooltip>
                    </button>
                )}

                <button onClick={openModalMediaSettings}>
                    <img
                        src={deviceSettingsIcon}
                        alt="device settings"
                        data-tip
                        data-for="deviceSettingsIcon"
                    />
                    <ReactTooltip id="deviceSettingsIcon" place="bottom" effect="solid">
                        Проверка настроек устройства
                    </ReactTooltip>
                </button>
            </div>
        )
    }

    return (
        <div className="toolbar">
            <button onClick={checkForAccidentalButtonPresses} style={{ opacity: 0.6 }}>
                <ExpressLeave alt="express leave icon" data-tip data-for="expressLeaveTip" />
                <ReactTooltip id="expressLeaveTip" place="bottom" effect="solid">
                    Экстренное завершение
                </ReactTooltip>
            </button>
            <button onClick={onChatLeave}>
                <img src={leave} alt="leave icon" data-tip data-for="leaveIconTip" />
                <ReactTooltip id="leaveIconTip" place="bottom" effect="solid">
                    Завершить занятие
                </ReactTooltip>
            </button>

            <button
                onClick={openFilesModal}
                className={classNames({ 'received-message': isReceivedMessage })}
            >
                <img src={cloudUploadIcon} alt="cloud icon" data-tip data-for="cloudTip" />
                <ReactTooltip id="cloudTip" place="bottom" effect="solid">
                    Обмен файлами
                </ReactTooltip>
            </button>

            {isActiveChatMessenger ? (
                <button onClick={onSetIsActiveChatMessenger}>
                    <img src={profile} alt="profile icon" data-tip data-for="activeMessengerTip" />
                    <ReactTooltip id="activeMessengerTip" place="bottom" effect="solid">
                        Скрыть чат
                    </ReactTooltip>
                </button>
            ) : (
                <button onClick={onSetIsActiveChatMessenger}>
                    <img
                        src={unreadMsg ? messengerActive : messenger}
                        alt="messenger icon"
                        data-tip
                        data-for="messengerTip"
                    />
                    <ReactTooltip id="messengerTip" place="bottom" effect="solid">
                        Перейти в чат
                    </ReactTooltip>
                </button>
            )}

            <button onClick={openModalMediaSettings}>
                <img
                    src={deviceSettingsIcon}
                    alt="device settings"
                    data-tip
                    data-for="deviceSettingsIcon"
                />
                <ReactTooltip id="deviceSettingsIcon" place="bottom" effect="solid">
                    Проверка настроек устройства
                </ReactTooltip>
            </button>

            {isArbitration && (
                <ModalArbitration
                    title="Предупреждение"
                    content={
                        loading ? (
                            <div className="modal__loading">
                                <img src={loadingIcon} alt="loading icon" />
                            </div>
                        ) : (
                            <div className="modal__alert">
                                <div className="modal__alertText">
                                    <p>
                                        Нажатая кнопка "Экстренное завершение" запустит Арбитраж,
                                        <br />
                                        потраченные вами минуты будут заморожены, <br /> а педагог
                                        не получит денег до окончания разбирательства. <br />
                                        Продолжить?
                                    </p>
                                </div>
                                <div className="modal__buttons">
                                    <Button type="secondary" onClick={resetArbitration}>
                                        Нет
                                    </Button>
                                    <Button type="secondary" onClick={onExpressChatLeave}>
                                        Да
                                    </Button>
                                </div>
                            </div>
                        )
                    }
                    close={resetArbitration}
                />
            )}
        </div>
    )
}

export default Toolbar
